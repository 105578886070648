import React,{ useState,useEffect  } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

export default function PaymentForm({setPayType}) {
    const [loading, setLoading] = useState(true);
  const [paymentLink, setPaymentLink] = useState('');
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('Choose wisely');
  const handleRadioChange = (event) => {
      setValue(event.target.value);
      setPayType(event.target.value);
      setHelperText('Please Select Payment method');
      setError(false);
    };

  useEffect(() => {
  }, []);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method smdfsfjskdfjskdf
      </Typography>
      <Grid spacing={3}>
        <RadioGroup
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={value}
          onChange={handleRadioChange}>
          <FormControlLabel value="cod" control={<Radio />} label="COD (Case On Delivery)" />
          <FormControlLabel value="online" control={<Radio />} label="Online Payment" />
        </RadioGroup>
      </Grid>
    </React.Fragment>
  );
}
