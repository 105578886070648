import {Helmet} from "react-helmet";
import './notfound.css';
const NotFOundPage =()=>{
window.location.replace("https://www.indiantrolley.com/404.php");
	return(
		<>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Page Not Found - Indiantrolley</title>

			{/* Open Graph */}
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content="Indiantrolley.com" />
			<meta property="og:title" content="Page Not Found - Indiantrolley" />
			<meta property="og:description" content="The page you are looking for does not exist on Indiantrolley." />

			{/* Additional Metadata */}
			<meta name="description" content="The page you are looking for does not exist on Indiantrolley." />
			<meta name="robots" content="noindex, nofollow" />
			<link rel="canonical" href={window.location.href} />
			<link rel="alternate" href={window.location.href} hrefLang="en" />
		</Helmet>
		<section class="page_404">
			<div class="container">
				<div class="row">	
				<div class="col-sm-12 ">
				<div class="col-sm-10 col-sm-offset-1  text-center">
				<div class="four_zero_four_bg">
					<h1 class="text-center ">404</h1>
				
				
				</div>
				
				<div class="contant_box_404">
				<h3 class="h2">
				Look like you're lost
				</h3>
				
				<p>the page you are looking for not avaible!</p>
				
				<a href="./" class="link_404">Go to Home</a>
			</div>
				</div>
				</div>
				</div>
			</div>
		</section>
		</>
	);
}
export default NotFOundPage;
