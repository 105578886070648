import { Fragment, useEffect, useState } from "react";
import {BASE_URL} from '../config/baseUrl';

const Sitemap = () => {
    const [sitemapData, setSitemapData] = useState([]);

    const getProductData = async () => {
        try {
            const response = await fetch(`${BASE_URL}sellers/get_cat_sub_cat.php`,
                {
                    method: "GET",
                }
            );
            const data = await response.json();
            // console.log("Received sitemap:", data);
            if (data.status) {
                setSitemapData(data?.data);
            } else {
                setSitemapData([]); // No matching product found
            }
        } catch (error) {
            console.error("Error fetching product information:", error);
            setSitemapData([]); // Handle error case
        }
    }

    useEffect(() => { getProductData() }, []);

    return (
        <Fragment>
            <section style={{
                paddingLeft: 30,
                paddingRight: 30,
            }}>
                <h2>Categories</h2>
                <p><br/></p>
                {sitemapData?.map((item,index) => {
                    return (
                        <div key={index}>
                            <h3>{item?.category_name}</h3>
                            <p>
                                {item?.subcategories?.map((sItem) => {
                                    return (
                                        <span>
                                            <a
                                            style={{
                                                cursor:'pointer'
                                            }} href={"../../../../"+item.slug+"/"+sItem?.slug}>{sItem?.name}</a>
                                            <span>{`   |   `}</span>
                                        </span>
                                    )
                                })}
                            </p>
                        </div>
                    )
                })}

            </section>
        </Fragment>
    );
};

export default Sitemap;
