import { Fragment } from "react";

const Privacypolicy = () => {

    return (
        <Fragment>
            <section style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                }}>
                <h1>Privacy Policy</h1>
                <p>We recognize the importance of safe transactions and data protection, and we are grateful for your trust. This Privacy Policy describes how IndianTrolley and its affiliates (collectively, "IndianTrolley," "we," "our," or "us") gather, use, share, or process personal data about you via the company's mobile application, m-site, and website, <a href="https://www.indiantrolley.com">www.indiantrolley.com</a> (collectively, the "Platform").</p>
                <p>It is crucial to remember that although you can browse some parts of the Platform without disclosing any information, we do not use this Platform to sell any goods or services to customers outside of India. You specifically agree to comply by the terms and conditions stated in this Privacy Policy, the Terms of Use, and the applicable service/product terms and conditions by using this Platform, giving us your information, or utilizing our products/services. You also agree that Indian law, including privacy and data protection regulations, will apply to this agreement. Kindly do not use or use our Platform if you disagree with this.</p>
                <h2>Collection of Information</h2>
                <p>We occasionally receive information from you when you use our platform, which we gather and keep. You are not required to provide your identity or other personal information to us in order to use the Platform. We will not consider you anonymous after you provide us with your personal information. We make every effort to clearly identify the mandatory and optional fields. You can choose not to use any specific service, product, or feature on the Platform if you would rather not to divulge information.</p>
                <h2>Your Consent</h2>
                <p>You agree that this Privacy Policy will be followed when collecting, using, storing, disclosing, and otherwise processing your information (including sensitive personal information) on our platform when you visit it or submit it. You warrant that you have the right to give us access to any personal information about other individuals and that we may use it in line with this privacy policy if you do.</p>
                <h2>Developer Information</h2>
                <p>If you have any questions or concerns about this Privacy Policy or the practices of this Platform, you can contact us using the following information:</p>
                <ul>
                    <li><strong>Email address:</strong> contact.excellentmirror@gmail.com</li>
                    <li><strong>Phone number:</strong> +918209454377</li>
                    <li><strong>Website:</strong> <a href="https://www.indiantrolley.com">www.indiantrolley.com</a></li>
                </ul>
            </section>
        </Fragment>
    );
};

export default Privacypolicy;
