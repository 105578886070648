import React from "react"
import "./style.css"
import { Col, Container, Row } from "react-bootstrap"
import logoImage from "./logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const router = useNavigate();
  return (
<div className="container my-5">
<footer className="bg-dark text-center text-white">
  
  <div className="container p-4" style={{background:'#5a644f'}}>
  
    <section className="mb-4">
  
      <a className="btn btn-outline-light btn-floating m-1" href="https://www.facebook.com/profile.php?id=61562055307199" role="button"
        ><i className="fab fa-facebook-f"></i
      ></a>

      <a className="btn btn-outline-light btn-floating m-1" href="https://in.pinterest.com/indiantrolley/" role="button"
        ><i className="fab fa-pinterest"></i
      ></a>

      <a className="btn btn-outline-light btn-floating m-1" href="https://maps.app.goo.gl/inF4Zn5AYgzuuyVAA" role="button"
        ><i className="fab fa-google"></i
      ></a>

      <a className="btn btn-outline-light btn-floating m-1" href="https://www.instagram.com/indtrolley" role="button"
        ><i className="fab fa-instagram"></i
      ></a>

      <a className="btn btn-outline-light btn-floating m-1" href="https://www.linkedin.com/company/indiantrolley" role="button"
        ><i className="fab fa-linkedin-in"></i
      ></a>
    </section>

    <section className="">
      <form action="">

        <div className="row d-flex justify-content-center">

          <div className="col-auto">
            <p className="pt-2">
              <strong>Sign up for our newsletter</strong>
            </p>
          </div>

          <div className="col-md-5 col-12">

            <div className="form-outline form-white mb-4">
              <input type="email" id="form5Example2" className="form-control" />
              <label className="form-label" htmlFor="form5Example2">Email address</label>
            </div>
          </div>

          <div className="col-auto">

            <button type="submit" className="btn btn-outline-light mb-4">
              Subscribe
            </button>
          </div>

        </div>

      </form>
    </section>

    <section className="mb-4">
      <p>
        Women's, Men's, and Children's clothes are available at Indian Trolley at negotiable prices, with discounted offers. Currently, there is a special offer on women's kurtis and men's t-shirts.
      </p>
    </section>
    <section className="">

      <div className="row">

        <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 className="text-uppercase">Man Categories</h5>

          <ul className="list-unstyled mb-0">
            <li>
              <a href="/cloth/man-tshirt" className="text-white">Man T-shirt</a>
            </li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 className="text-uppercase">Women Categories</h5>

          <ul className="list-unstyled mb-0">
            <li>
              <a href="/cloth/womens-kurtas" className="text-white">Womens Kurtas</a>
            </li>
            <li>
              <a href="/cloth/woman-jeans" className="text-white">Woman Jeans</a>
            </li>
            <li>
              <a href="/cloth/woman-tshirt-and-topper" className="text-white">Woman T-shirt and topper</a>
            </li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 className="text-uppercase">Customer Care</h5>

          <ul className="list-unstyled mb-0">
            <li>
              <a href="https://www.indiantrolley.com/security" className="text-white">Security</a>
            </li>
            <li>
              <a href="https://www.indiantrolley.com/privacypolicy" className="text-white">Privacy Policy</a>
            </li>
            <li>
              <a href="https://www.indiantrolley.com/Order-Cancellation-and-Return-Policy.html" className="text-white">Order Cancellation and Return Policy</a>
            </li>
            <li>
              <a href="https://www.indiantrolley.com/shipping.html" className="text-white">Shipping</a>
            </li>
            <li>
              <a href="https://www.indiantrolley.com/teamofuse.html" className="text-white">Terms of Use</a>
            </li>
          </ul>
        </div>

        <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 className="text-uppercase">Useful Links</h5>

          <ul className="list-unstyled mb-0">
            <li>
              <a href="https://www.indiantrolley.com/aboutus.html" className="text-white">About Us</a>
            </li>
            <li>
              <a href="/sitemap" className="text-white">Sitemap</a>
            </li>
            <li>
              <a href="tel:+918209454377" className="text-white">+91 8209454377</a>
            </li>
            <li>
              <a href="tel:+919887799122" className="text-white">+91 9887799122</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>

  <div data-nosnippet className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
    <p className="text-white"><b>Disclaimer:</b>Indian Trolley, The information provided on this website is for general informational purposes only. While we strive to ensure the accuracy and reliability of the information, Indian Trolley makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is strictly at your own risk. We endeavor to provide accurate descriptions and prices of products; however, errors may occur. Indian Trolley reserves the right to correct any errors, inaccuracies, or omissions, and to change or update information at any time without prior notice. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Indian Trolley will not be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
  </div>
    <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
    © 2015-2024 Copyright:
    <a className="text-white" href="https://www.indiantrolley.com/">Indiantrolley.com</a>
  </div>
</footer>
  
</div>
  )
}

export default Footer
