import { Fragment, useEffect, useState } from "react";

const Privacypolicy = () => {

    return (
        <Fragment>
            <section style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                }}>
                <h2>Safe and Secure Shopping</h2>
                  <h3>Is making online payments secure on IndiaMART?</h3>
                  <p>Yes, making online payments is secure on IndiaMART.</p>

                  <h3>Does IndiaMART keep a record of my credit or debit card details?</h3>
                  <p>No, IndiaMART simply keeps your card number's last four digits on file in order to identify it.</p>

                  <h3>What credit/debit cards are accepted on IndiaMART?</h3>
                  <p>Credit and debit cards from VISA, MasterCard, Maestro, Rupay, American Express, are accepted at IndiaMART.</p>

                  <h3>Do you accept credit/debit cards from foreign countries as payment methods?</h3>
                  <p>Yes, indeed! Credit and debit cards from banks in India, as well as those from Australia, Austria, Belgium, Canada, Cyprus, Denmark, Finland, France, Germany, Ireland, Italy, Luxembourg, the Netherlands, New Zealand, Norway, Portugal, Singapore, Spain, Sweden, the UK, and the US, are accepted by IndiaMART. Please be aware that we cannot process eGV payments or top-ups using credit or debit cards issued abroad.</p>

                  <h3>What other payment options are available on India Mart?</h3>
                  <p>We accept the following payment methods in addition to credit and debit cards: cash on delivery, Equated Monthly Installments (EMI), E-gift vouchers, UPI, Wallet, and Paytm Postpaid. Internet banking is supported by 44 banks.</p>
            </section>
        </Fragment>
    );
};

export default Privacypolicy;
