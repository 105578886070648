import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddressForm({
  setZip,
  setFirst,
  setMobile,
  setAddress1
}) {
  // State variables to store form field values and validation errors
  const [values, setValues] = React.useState({
    firstName: '',
    mobile: '',
    address1: '',
    zip: ''
  });
  const [errors, setErrors] = React.useState({
    firstName: '',
    mobile: '',
    address1: '',
    zip: ''
  });
  React.useEffect(()=>{
    var addressData = JSON.parse(localStorage.getItem('addressData'));
    if(addressData){
      setValues({
            ...values,
            firstName: addressData.first,
            mobile: addressData.mobile,
            address1: addressData.address1,
            zip: addressData.zip,
          });
    }
  },[]);
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Basic validation to check if required fields are filled
    const newErrors = { ...errors };

    if (!values.firstName) {
      newErrors.firstName = 'First name is required';
    } else {
      newErrors.firstName = '';
    }

    if (!values.mobile) {
      newErrors.mobile = 'Mobile number is required';
    } else {
      newErrors.mobile = '';
    }

    if (!values.address1) {
      newErrors.address1 = 'Address line 1 is required';
    } else {
      newErrors.address1 = '';
    }

    if (!values.zip) {
      newErrors.zip = 'Zip code is required';
    } else {
      newErrors.zip = '';
    }

    // Update the errors state
    setErrors(newErrors);

    // Check if there are no validation errors
    if (
      !newErrors.firstName &&
      !newErrors.mobile &&
      !newErrors.address1 &&
      !newErrors.zip
    ) {
      // Proceed with form submission
      // Call your function to handle form submission here
    } else {
      // If there are validation errors, stop form submission
      return;
    }
  };
  const getAddress = async () =>{
    var user = JSON.parse(localStorage.getItem('userdata'));
    try {
      const response = await fetch('https://api.indiantrolley.com/sellers/get_address.php?user_id='+user.id, {
        method: 'GET'
      });

      if (response.ok) {
        const responseData = await response.json();
        if(responseData.status){
          setZip(responseData?.category[0]?.zip);
          setFirst(responseData?.category[0]?.first);
          setMobile(responseData?.category[0]?.mobile);
          setAddress1(responseData?.category[0]?.address1);
          setValues({
            ...values,
            firstName: responseData?.category[0]?.first,
            mobile: responseData?.category[0]?.mobile,
            address1: responseData?.category[0]?.address1,
            zip: responseData?.category[0]?.zip,
          });
        }
      } else {
        console.log('Failed to send data');
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="Full Name"
            fullWidth
            autoComplete="given-name"
            error={!!errors.firstName}
            helperText={errors.firstName}
            variant="standard"
            value={values.firstName}
            onChange={(e)=>{setFirst(e.target.value),setValues({
              ...values,
              firstName: e.target.value,
              mobile: values.value,
              address1: values.address1,
              zip: values.zip,
            })}}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="Mobile"
            name="mobile"
            label="Mobile Number"
            fullWidth
            error={!!errors.mobile}
            helperText={errors.mobile}
            autoComplete="shipping address-line1"
            variant="standard"
            value={values.mobile}
            onChange={(e)=>{setMobile(e.target.value),setValues({
              ...values,
              firstName: values.first,
              mobile: e.target.value,
              address1: values.address1,
              zip: values.zip,
            })}}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            error={!!errors.address1}
            helperText={errors.address1}
            autoComplete="shipping address-line1"
            variant="standard"
            value={values.address1}
            onChange={(e)=>{setAddress1(e.target.value),setValues({
              ...values,
              firstName: values.first,
              mobile: values.mobile,
              address1: e.target.value,
              zip: values.zip,
            })}}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip or Postal code"
            fullWidth
            error={!!errors.zip}
            helperText={errors.zip}
            autoComplete="shipping postal-code"
            variant="standard"
            value={values.zip}
            onChange={(e)=>{setZip(e.target.value),setValues({
              ...values,
              firstName: values.value,
              mobile: values.value,
              address1: values.address1,
              zip: e.target.value,
            })}}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
