import React, { useEffect,useState } from "react";
import './Profile.Style.css';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LogoutIcon from '@mui/icons-material/Logout';
import EmailIcon from '@mui/icons-material/Email';
import { useDispatch, useSelector } from "react-redux";
import { AddUser } from "../app/features/user/userSlice";
import { useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  placeContent: 'center',
  justifyContent: 'center',
  textAlign:'center'
};
const Profile = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
      const [open, setOpen] = useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
    const [orderData, setOrderData] = useState([]);
    let { user } = useSelector((state) => state.user);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
        const getCartItems = async () => {

        try {
          await fetch('https://api.indiantrolley.com/sellers/user-order.php?user='+user.id, {
            method: "GET",
          }).then((res) => {
            res.json().then((response) => {
              if (response?.status) {
                console.log('see dataa:', response?.data);
                setOrderData(response?.data || []);
              } else {
                console.error(response?.message);
              }
            })
          });
        }
        catch (err) {toast.error('Something went wrong!'); }
      }
    useEffect(()=>{
    if(!user){
      user = JSON.parse(localStorage.getItem('userdata'));
    }
        getCartItems();
    },[]);

    return (
        <section className="main-container">
            <Container fixed>
                <h2>Profile</h2>
                <p><br /></p>
                <Grid container sx={{display: 'grid'}}>
                    <Grid item sm={12}>
                        <Card>
                            <Typography variant="h5" gutterBottom>
                                Menu
                            </Typography>
                            <br />

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <h6>My Orders</h6>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                {orderData.map((item, index) => {
                                                    return (
                                                        <ListItem
                                                            key={index}
                                                            style={{ cursor: 'pointer', marginTop: 5,width:'100%' }}
                                                            onClick={() => window.location.href = "order/"+item.ordercode}>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <ShoppingBagIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText sx={{ width: '100%'}} primary={"Order Code"} secondary={"#"+item.ordercode} />

                                                            
                                                            <ListItemText sx={{ width: '100%'}} primary={"                         "}/>
                                                            <ListItemText sx={{ width: '100%'}} primary={"Qty"} secondary={item.qty}/>
                                                            <ListItemText sx={{ width: '100%'}} primary={"Amount"}
                                                            secondary={"₹ "+item.amount}/>
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    Account
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ListItem
                                        style={{ cursor: 'pointer', marginTop: 5 }}
                                        onClick={() => alert('')}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <EmailIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Contact Us"/>
                                    </ListItem>

                                    <ListItem
                                        style={{ cursor: 'pointer', marginTop: 5 }}
                                        onClick={() => {
                                            localStorage.removeItem('userdata');
                                            dispatch(AddUser(null));
                                            navigation('/');
                                        }}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LogoutIcon style={{color:'red'}}/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Logout" style={{color:'red'}} />
                                    </ListItem>
                                </AccordionDetails>
                            </Accordion>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Profile;