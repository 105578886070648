import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import "./Signup.css";
import email_icon from "./login_png/email.png";
import password_icon from "./login_png/password.png";
import { useNavigate } from 'react-router-dom';
import {AddUser} from "../app/features/user/userSlice";
import { useDispatch } from 'react-redux';
import { BASE_URL } from '../config/baseUrl';
const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: ""
  });
  const [otps, setOtps] = useState(Math.floor(Math.random() * 8888) + 1111);
  const [text, setText] = useState("Send OTP");
  const [mobile, setMobile] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(name == "mobile"){
    setMobile(value);
    }
    setUser({
      ...user,
      [name]: value
    });
  }

const handleOtp = async ()=>{
  setText("Login");
  document.getElementById('otp').style.display = "";
  try {
    const response = await fetch(`${BASE_URL}sellers/request-otp.php?otp=${otps}&mobile=${mobile}`,
    {
      method: "GET",
    }
    );
    if(response.status_code != 200){
      alert("Invalid Mobile Number");
      setText("Send OTP");  
    }
    // const data = await response.json();
    // setArrival(data?.product);
  } catch (error) {
    setText("Send OTP");
    // console.error("Error fetching product information:", error);
  }
}

  const handleSubmit = async (e) => {
    if(otps != user.password){
      alert("Invalid Otp");
      return null;
    }
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('username', "NA");
      formData.append('email', user.email);
      formData.append('contact', user.email);
      formData.append('password', "");
      const resp = await fetch('https://api.indiantrolley.com/sellers/Signup.php', {
        method: "POST",
        body: formData,
      });
  
      const resData = await resp.json();
      if (resData.status === true) {
        dispatch(AddUser(resData?.data));
        localStorage.setItem('userdata', resData?.data);
          const searchParams = new URLSearchParams(window.location.search);
          const source = searchParams.get('source');
          localStorage.setItem('userdata', JSON.stringify(resData?.data));
          if (source) {
            window.location.href = source;
          }else{
            navigate("/");
          }
      } else {
      alert("Invalid Number");
        toast.error(resData.message);
      }
    } catch (error) {
      console.error('Error during login:', error.message);
    }
  }
  useEffect(() => {
    if(!user){
        user = JSON.parse(localStorage.getItem('userdata'));
      }
    document.getElementsByTagName('footer')[0].style.display='none';
  }, []);
  return (
      <div className='container1'>
            <div className="card">
              <div className="card-body">
                  <div className='header1'>
                  <div className='text'>Login</div>
                  <div className='underline'></div>
                </div>

                  <div className='input'>
                    {/* <img src={email_icon} alt='' /> */}
                    <input type='number' name='mobile' id='mobile' placeholder='Mobile Number' onChange={handleInput} required/>
                  </div>
                  <div className='input' id='otp' style={{display:'none'}}>
                    <img src={password_icon} alt='' />
                    <input type='text' name='password' id='password' placeholder='Otp' onChange={handleInput} autoComplete='off' />
                  </div>
                  {/*<div className="loginsignup-login">Forget Password?<span  onClick={()=>navigate('/signup')}>  Click Here!</span></div>*/}
                  <div className="submit-container">

                  {text=="Send OTP"?
                  <button onClick={handleOtp} className='submit'>{text}</button>:
                  <button onClick={handleSubmit} className='submit'>{text}</button>}
                    {/* <button className='submit' onClick={()=>navigate('/signup')}>Sign Up</button> */}
                  </div>
              </div>
            </div>
      </div>
  );
}

export default Login;
