import { useEffect, useState } from "react";
import logoImage from "./logo.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./navbar.css";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
const NavBar = () => {
  let { user } = useSelector((state) => state.user);
  const { cartList } = useSelector((state) => state.cart);
  const [expand, setExpand] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  // fixed Header
  function scrollHandler() {
    if (window.scrollY >= 100) {
      setIsFixed(true);
    } else if (window.scrollY <= 50) {
      setIsFixed(false);
    }
  }
  const search = async (query) => {
      try {
        const response = await fetch(`https://api.indiantrolley.com/sellers/search.php?query=${query}`);
        if (!response.ok) {
          throw new Error('Failed to fetch search results');
        }
        const data = await response.json();
        // console.log(data);
        setResults(data.subcategories);
      } catch (error) {
        setError(error.message);
      }
  }
  if(!user){
    user = JSON.parse(localStorage.getItem('userdata'));
  }
  window.addEventListener("scroll", scrollHandler);
  return (
    <Navbar
      fixed="top"
      expand="md"
      className={isFixed ? "navbar fixed" : "navbar"}
    >
      <Container className="navbar-container">
        <Navbar.Brand>
          <NavLink to='/'>
            <img src={logoImage} alt="Indian Trolley" className="logo" 
              style={{ maxWidth: '13%',position: 'relative',left: '15px' }} />
          </NavLink>
        </Navbar.Brand>
        {/* Media cart and toggle */}
        <div className="d-flex">
          <div className="media-cart">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="black"
              className="nav-icon"
            >
              <path
                fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                clipRule="evenodd"
              />
            </svg>
            <Link
              aria-label="Go to Cart Page"
              to="/cart"
              className="cart">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="black"
                className="nav-icon">
                <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
              </svg>
            </Link>
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => {
              setExpand(expand ? false : "expanded");
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Item>
              <Link
                aria-label="Man best wearing collection"
                className="navbar-link"
                to="/bags/trolley-bags"
                onClick={() => {setExpand(false);window.location.href = '/bags/trolley-bags'}}
              >
                <span className="nav-link-label">Trolley bags</span>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                aria-label="Man best wearing collection"
                className="navbar-link"
                to="/men-best-wearing-collection"
                onClick={() => {setExpand(false);window.location.href = '/men-best-wearing-collection'}}
              >
                <span className="nav-link-label">Man</span>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                aria-label="Ladies best wearing collection"
                className="navbar-link"
                to="/ladies-best-wearing-collection"
                onClick={() => {setExpand(false);window.location.href = '/ladies-best-wearing-collection'}}
              >
                <span className="nav-link-label">Ladies</span>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="d-none d-md-block">
                 <span className='fa fa-search ml-4' id="toggle" onClick={() => setToggle(!toggle)}></span>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={user!=null ? "/profile":"/login"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="black"
                className="nav-icon"
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                  clipRule="evenodd"
                />
              </svg>
              </Link>
            </Nav.Item>
            <Nav.Item className="expanded-cart">
              <Link
                aria-label="Go to Cart Page"
                to={user!=null ? "/cart":"/login"}
                onClick={() => setExpand(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="black"
                  className="nav-icon"
                >
                  <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                </svg>
              </Link>
            </Nav.Item>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
        {
          toggle && ( 
            <div className="search-main">
              <div className="search-box-inner container bg-white p-3" style={{display: 'list-item'}}>
                  <form action="search" className="search-form">
                      <div className="form-group">
                          <input
                              type="search"
                              name="query"
                              id="site_search"
                              className="form-control"
                              placeholder="What are you looking for"
                              onChange={(e)=>{search(e.target.value)}}
                          />
                          <span className='fa fa-search'></span> {/* Search icon */}
                          <span className='fa fa-times' onClick={() => setToggle(!toggle)}></span> {/* Close icon */}
                      </div>
                  </form>
                  {/* Search suggestion section */}
                  <ul className="list-group" style={{ width: '89.5%', overflowY: 'auto', maxHeight: '400px' }}>
                    {results?.map((item) => (
                      <li key={item.id} className="list-group-item"><a href={item?.full_slug}>{item?.subcategory_name}</a></li>
                    ))}
                  </ul>
              </div>
          </div>
      )}
    </Navbar>
  
  );
};

export default NavBar;
