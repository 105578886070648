import { lazy, Suspense, useEffect,useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import Checkout from "./components/Checkout/Checkout";
import { ToastContainer } from "react-toastify";
// import Box from '@mui/material/Box';
// import Tabs, { tabsClasses } from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import "react-toastify/dist/ReactToastify.css";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Sitemap from "./pages/Sitemap";
import Terms from "./pages/Terms";
import Security from "./pages/Security";
import Privacypolicy from "./pages/Privacypolicy";
import { useDispatch } from "react-redux";
import { AddUser } from "./app/features/user/userSlice";
import Profile from "./pages/Profile";
import ProductSizeSelection from "./pages/ProductSizeSelection";
import NotFOundPage from './NotFOundPage.jsx';
const Home = lazy(() => import("./pages/Home"));
const Shop = lazy(() => import("./pages/Shop"));
const Categories = lazy(() => import("./pages/Categories"));
const Cart = lazy(() => import("./pages/Cart"));
const Buy = lazy(() => import("./pages/Buy"));
const Product = lazy(() => import("./pages/Product"));
const Order = lazy(() => import("./pages/Order"));
function App() {
  const [value, setValue] = useState(0);
  const [main, setMain] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const getProductData = async () => {
    try {
      const response = await fetch(`https://api.indiantrolley.com/sellers/main_category.php`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      // console.log("Received data:", data);
      if (data) {
        sessionStorage.setItem('mainCat', JSON.stringify(data));
        setMain(data);
      } else {
        // setProductData([]); // No matching product found
      }
    } catch (error) {
      console.error("Error fetching product information:", error);
      // setProductData([]); // Handle error case
    }
  }
  useEffect(() => {
    getProductData();
    let data = localStorage.getItem('userdata');
    if (data != null) {
     dispatch(AddUser(JSON.parse(data))); 
   }else{
    var userData = {
        email:"guest@indiantrolley.com",
        id:Math.floor(Math.random() * 100)
      };
      localStorage.setItem('userdata', JSON.stringify(userData));
   }
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          bodyClassName="toastBody"
        />
        <NavBar />
{/*          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            aria-label="Products Main Categories"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}>
            <Tab label="Item One" />
            <Tab label="Item Two" />
            <Tab label="Item Three" />
            <Tab label="Item Four" />
            <Tab label="Item Five" />
            <Tab label="Item Six" />
            <Tab label="Item Seven" />
          </Tabs>*/}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/404.html" element={<NotFOundPage />} />
          <Route path="/order/:tracking" element={<Order />} />
          <Route path="product/*" element={<Navigate to="/404.html" replace />} />
          <Route path="/checkout/cart" element={<Checkout />} />
          <Route path="/man" element={<Categories />} />
          <Route path="/woman" element={<Categories />} />
          <Route path="/search" element={<Categories />} />
          <Route path="/:categories" element={<Categories />} />
          <Route path="/pl/:slug" element={<Product />} />
          <Route path="/:categories/:subcategories" element={<Categories />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/buy" element={<Profile />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/:slug" element={<Checkout />} />
          <Route path="/checkout/:slug/:qty" element={<Checkout />} />
          <Route path="/checkout/:slug/:qty/:size" element={<Checkout />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/check" element={<ProductSizeSelection />}/>
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/security" element={<Security />} />
          // <Route path="/checkout/:slug/:qty" element={<Checkout />} />
          // <Route path="/checkout/:slug/:qty" element={<Checkout />} />
          // <Route path="/checkout/:slug/:qty" element={<Checkout />} />
        </Routes>
        <Footer/>
      </Router>
    </Suspense>
  );
}

export default App;
