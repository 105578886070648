import React, { useState } from 'react';
import { toast } from "react-toastify";
import "./Signup.css";
import user_icon from "./login_png/person.png";
import email_icon from "./login_png/email.png";
import password_icon from "./login_png/password.png";
import contact_icon from "./login_png/contact.png";
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const [user, setUser] = useState({
    username: "",
    email: "",
    contact: "",
    password: ""
  });
  const navigate=useNavigate()

   const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUser({
      ...user,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(user);
  
    try {
      const formData = new FormData();
      formData.append('username', user.username);
      formData.append('email', user.email);
      formData.append('contact', user.contact);
      formData.append('password', user.password);
  
      const resp = await fetch('https://api.indiantrolley.com/sellers/Signup.php', {
        method: "POST",
        body: formData,
      });
  
      const resData = await resp.json();
      if (resData.status === true) {
        setUser({
          username: "",
          email: "",
          phone: "",
          password: ""
        })
        // console.log(resData.message);
        toast.success(resData.message);
        navigate("/login");
      } else {
        // console.log('something went wrong!');
        toast.error(resData.message);
      }
  
    } catch (error) {
      toast.error('Error during registration:', error.message);
    }
  }
  document.getElementsByTagName('footer')[0].style.display='none';
  return (
    <div className='container1'>
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-body">
            <div className='header1'>
              <div className='text'>Sign Up</div>
              <div className='underline'></div>
            </div>
            <div className="inputs">
              <div className='input'>
                <img src={user_icon} alt='' />
                <input type='text' name='username'  value={user.username} id='username' placeholder='Name' onChange={handleInput} required autoComplete='off'/>
              </div>
              <div className='input'>
                <img src={email_icon} alt='' />
                <input type='email' name='email' value={user.email} id='email' placeholder='Email' onChange={handleInput} required autoComplete='off'/>
              </div>
              <div className='input'>
                <img src={password_icon} alt='' />
                <input type='password' name='password' value={user.password} id='password' placeholder='Password' onChange={handleInput} required autoComplete='off'/>
              </div>
              <div className='input'>
                <img src={contact_icon} alt='' />
                <input type='text' name='contact' value={user.contact} id='contact' placeholder='Contact' onChange={handleInput} required autoComplete='off'/>
              </div>
            </div>
            <div className="submit-container">
              <button type='submit' className='submit'>Sign Up</button>
            </div>
            <div className="loginsignup-login text-center">
              <p onClick={() => navigate("/login")}>Already have an account?<span>  Login here</span></p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Signup;
