import React,{ useState,useEffect  } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import Sucess from './sucess';
import { useParams } from 'react-router-dom';
import {BASE_URL} from '../../config/baseUrl';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.indiantrolley.com/">
        Indian Trolley
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Shipping address', 'Payment details', 'Review your order'];

export default function Checkout() {
  const { slug,qty,size } = useParams();
  let { user } = useSelector((state) => state.user);
  const router = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [first, setFirst] = React.useState('');
  const [address1, setAddress1] = React.useState('');
  const [zip, setZip] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [product, setProduct] = React.useState({});
  const [payType,setPayType] = React.useState('COD');
  const [total,setTotal] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [orderCode,setOrderCode] =React.useState(Math.random().toString(36).substring(2, 10));
  const [errors, setErrors] = React.useState({
    first: '',
    address1: '',
    zip: '',
    mobile: ''
  });
  if(!user){
      user = JSON.parse(localStorage.getItem('userdata'));
    }
  const getCartItems = async () => {
    const formData = new FormData();
    formData.append("user_id", 1 || global.user_id);

    try {
      await fetch(
        `${BASE_URL}sellers/get_cart_items.php`,
        {
          method: "POST",
          body: formData,
        }
      ).then((res) => {
        res.json().then((response) => {
          if (response?.status) {
            console.log("see dataa:", response?.data);
            setProduct(response?.data || []);
            // getTotalPrice(response?.data);
          } else {
            toast.error(response?.message);
          }
        });
      });
    } catch (err) {
      toast.error("Something went wrong!");
    }
  };

  const getProductData = async () => {
    try {
      const response = await fetch(`${BASE_URL}sellers/product_by_slug.php?slug=${slug}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      // console.log("Received data:", data);
      if (data) {
        if(qty){
           data.product_info.forEach(function(product) {
                product.qty = qty; // Add qty property with a default value of 5
            }); 
        }
        setProduct(data.product_info);
      } else {
        // setProductData([]); // No matching product found
      }
    } catch (error) {
      console.error("Error fetching product information:", error);
      // setProductData([]); // Handle error case
    }
  }
  const get_buy_url = async () => {
    const formData = new FormData();
    formData.append("ordercode", orderCode);
    formData.append("email", 'a.praveenjangir@gmail.com');
    formData.append("price", total);
    formData.append("mobile", mobile);
    formData.append("name", first);
    try {
      await fetch(`${BASE_URL}sellers/razorpay.php`, {
        method: "POST",
        body: formData,
      }).then((res) => {
        console.log(res)
        res.json().then((response) => {
          console.log(response)
          if (response?.status) {
            setOpen(false);
            // console.log("see hererere:", response?.url);
            // return router(response?.url);
            window.location.replace(response?.url);
          } else {
            toast.error(response?.message);
          }
        });
      });
    } catch (err) {
      toast.error("Something went wrong!");
    }
  };
const insertOrder = async () => {
  for (const item of product) {
    var user1 = JSON.parse(localStorage.getItem('userdata'));
    const formData1 = new FormData();
    formData1.append('pid', item['id']);
    formData1.append('slug', item['slug']);
    formData1.append('name', item['product_name']);
    formData1.append('price', item['discount_price']);
    formData1.append('qty', item['qty']);
    formData1.append('amount', total);
    formData1.append('user_id', user1.id);
    formData1.append('ordercode', orderCode);
    formData1.append('size', 0);
    formData1.append('status', 'ORDERED');

    try {
      const response = await fetch('https://api.indiantrolley.com/sellers/insert_order.php', {
        method: 'POST',
        body: formData1
      });

      if (response.ok) {
        // console.log('Data sent successfully');
      } else {
        // console.log('Failed to send data');
      }
    } catch (error) {
      // console.log('Error:', error);
    }
  }
};
const handlePayment = async () => {
  try {
    // Fetch email and mobile number from form or state
    const options = {
      key: 'rzp_live_cY1LDcQql6dkZd', // Replace with your actual Razorpay Key ID
      amount: total*100, // Amount in smallest currency unit (10000 = ₹100)
      currency: 'INR',
      description: 'Indian Trolley, are more than just an e-commerce platform. We are a team of skilled and expert professionals committed to changing the way you shop online.',
      image: 'https://www.indiantrolley.com/static/media/logo.aa93f3e1f2700f2db183.png',
      prefill: {
        email: 'buy@indiantrolley.com',
        contact: mobile,
      },
      handler: function (response) {
        handlePaymentSuccess(response); // Call separate function to handle payment success
      },
      modal: {
        ondismiss: function () {
          handlePaymentDismiss();
        }
      }
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  } catch (error) {
    console.error('Error initializing Razorpay:', error);
  }
};

const handlePaymentSuccess = async (response) => {
  try {
    var razorpay_payment_id = response.razorpay_payment_id;
    const url = `https://api.indiantrolley.com/sellers/update-status.php?payment_id=${encodeURIComponent(razorpay_payment_id)}&ordercode=${encodeURIComponent(orderCode)}`;

    const response = await fetch(url, {
      method: 'GET'
      // Optionally, you can include headers here if needed
    });

    if (response.ok) {
      return router('profile');
      console.log('Data sent successfully');
      // Optionally, update state or perform additional actions upon successful update
    } else {
      console.error('Failed to send data');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

const handlePaymentDismiss = async () => {
  if (window.confirm('Are you sure you want to close the form?')) {
    console.log('Checkout form closed by the user');
    try {
      const dismissResponse = await fetch(`https://api.indiantrolley.com/sellers/update-status.php?status=CANCEL&ordercode=${encodeURIComponent(orderCode)}`, {
        method: 'GET'
      });
      if (dismissResponse.ok) {
        window.location.reload();
        console.log('Payment status updated to CANCEL');
      } else {
        console.error('Failed to update payment status');
      }
    } catch (error) {
      console.error('Error updating payment status:', error);
    }
  } else {
    console.log('Payment completion pending');
  }
};

  const handleNext = async() => { 
    setActiveStep(activeStep + 1);
    if(activeStep == 0){
    if (first=="") {
      alert('First name is required');setActiveStep(0);
    }
    if (!address1) {
      alert('Address is required');setActiveStep(0);
    }
    if (!mobile) {
      alert('Mobile number is required');setActiveStep(0);
    }
    }
    if(activeStep == 2){
      setOpen(true);
      var user1 = JSON.parse(localStorage.getItem('userdata'));
      const formData = new FormData();
      formData.append('first', first);
      formData.append('address1', address1);
      formData.append('zip', zip);
      formData.append('mobile', mobile);
      formData.append('paymentType', payType);
      formData.append('status', 0);
      formData.append('amount', total);
      formData.append('user_id', user1.id);
      formData.append('ordercode', orderCode);
      formData.append('size', size);
      var addressData = {
        mobile:mobile,
        address1:address1,
        zip:zip,
        first:first
      };
      localStorage.setItem('addressData', JSON.stringify(addressData));
      // formData.append('product', product);
      insertOrder();
      if(payType != 'cod'){
        handlePayment();
      }else{
        setOpen(false);
      }
      try {
        const response = await fetch('https://api.indiantrolley.com/sellers/order_system.php', {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          // window.location.href = '/profile';
          // setOpen(false);
          // console.log('Data sent successfully');
        } else {
          console.error('Failed to send data');
        }
      } catch (error) {
        console.error('Error:', error);
      }      
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const getStepContent=(step,product,setPayType)=> {
    switch (step) {
      case 0:
        return <AddressForm setFirst={setFirst} setAddress1={setAddress1} setZip={setZip} setMobile={setMobile}/>;
      case 2:
        return <PaymentForm setPayType={setPayType}/>;
        break;
      case 1:
        return <Review product={product} qty={qty} setTotal={setTotal}/>;
        break;
      case 3:
        <Sucess ordercode={orderCode}/>;
        break;
      default:
        throw new Error('Unknown step');
    }
  }
  useEffect(() => {
    document.getElementsByTagName('footer')[0].style.display='none';
    
    var addressData = JSON.parse(localStorage.getItem('addressData'));
    if(addressData){
      setMobile(addressData.mobile);
      setAddress1(addressData.address1);
      setZip(addressData.zip);
      setFirst(addressData.first);
    }
    if(!user){
      user = JSON.parse(localStorage.getItem('userdata'));
    }
    if(slug){
      getProductData();
    }else{
      getCartItems();
    }
  }, []);
  return (
    <React.Fragment>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 3 && payType == "cod" ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is #{orderCode}. We have emailed your order
                confirmation, and will send you an update when your order has
                shipped.
              </Typography>
                          <Button variant="contained" onClick={() => window.location.href = 'https://www.indiantrolley.com'} sx={{ mt: 3, ml: 1 }}>Redirect to Home</Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep,product,setPayType)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}
