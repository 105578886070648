import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

export default function Review({product,setTotal}) {
  let total = 0;
  product.forEach((product) => {
    total += product.discount_price * product.qty;
  })
  setTotal((total)*1.28);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {product.map((product) => (
          <ListItem key={product.product_name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={product.product_name} />
            <Typography variant="body2">₹{product.discount_price.slice(0,40)} * {product.qty}</Typography>
          </ListItem>
        ))}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="service charges (Delivery + Packaging and labeling)" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            ₹{total*0.08}
        </Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            ₹{total}
            <input value={total} type="hidden" name="input_total"/>
          </Typography>
        </ListItem>
      </List>
    </React.Fragment>
  );
}
