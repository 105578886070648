import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row', // Change to row direction
    alignItems: 'center',
  },
  checkboxLabel: {
    marginLeft: 5, // Adjust spacing between checkbox and label
  },
};

function NumberedCheckbox({ classes, number, checked, onChange }) {
  return (
    <div className={classes.checkboxContainer}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} />}
        label={<Typography className={classes.checkboxLabel}>{number}</Typography>}
      />
    </div>
  );
}

function ProductSizeSelection({ classes }) {
  const [selectedSizes, setSelectedSizes] = useState([]);

  const handleSizeToggle = (size) => () => {
    const currentIndex = selectedSizes.indexOf(size);
    const newSelectedSizes = [...selectedSizes];

    if (currentIndex === -1) {
      newSelectedSizes.push(size);
    } else {
      newSelectedSizes.splice(currentIndex, 1);
    }

    setSelectedSizes(newSelectedSizes);
  };

  return (
    <FormGroup>
 <input
  type="checkbox"
  id="react-option"
  value=""
  className="hidden peer"
  required=""
/>
<label
  for="react-option"
  className="flex items-center justify-center w-10 h-10 [box-shadow:1px_1px_0px_2px_#1e1e1e] duration-300 hover:[box-shadow:1px_1px_0px_1px_#000] hover:translate-y-0.5 hover:translate-x-0.5 bg-neutral-700 border-2 border-gray-800 rounded-full cursor-pointer text-neutral-50 peer-checked:[box-shadow:1px_1px_0px_2px_#28AB6B] peer-checked:border-none peer-checked:hover:[box-shadow:1px_1px_0px_1px_#28AB6B] peer-checked:bg-neutral-50 peer-checked:text-gray-800"
>
  <span className="text-lg font-semibold">5</span>
</label>

    </FormGroup>
  );
}

export default withStyles(styles)(ProductSizeSelection);
